import styled from "styled-components";

export const InfoNotice = styled.div`
  display: inline-block;
  padding: 5px 10px;
`;

export const ErreurNotice = styled(InfoNotice)`
  display: block;
  margin: 10px 0 0;
  background: rgba(255, 0, 0, 0.25);
`;
