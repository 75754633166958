// @ts-nocheck
import { Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import axios from "axios";

import { Identite } from "../../../interfaces/utilisateur.interface";
import { connexionSucces, connexionErreur } from "../utilisateur.slice";

import config from "../../../config";

export interface Response {
  user: Identite;
  cookie: string;
}

export const connexionAction =
  (
    sIdentifiant: string,
    sMotDePasse: string
  ): ThunkAction<void, Identite, unknown, Action<string>> =>
  async (dispatch) => {
    try {
      const response = await axios
        .post<Response>(`${config.apiURL}/auth/login`, {
          sIdentifiant,
          sMotDePasse,
        })
        .catch((err: any) => {
          dispatch(connexionErreur({ sErreur: err.response.data.message }));
        });

      if (response) {
        document.cookie = response.data.cookie;

        dispatch(connexionSucces(response.data.user));
      }
    } catch (err: any) {
      dispatch(connexionErreur({ sErreur: err.message }));
    }
  };
