import React, { FunctionComponent } from "react";

import AnimatedView from "../../core/layout/view/view.elements";
import { BouttonSection } from "./accueil.elements";

const AccueilView: FunctionComponent = () => {
  return (
    <AnimatedView view="Accueil">
      <BouttonSection to="/bouteilleO2" title="Réception de bouteilles O2">
        Réception de bouteilles&nbsp;O2
      </BouttonSection>

      <BouttonSection to="/cuves" title="Remplissage de cuves">
        Fractionnement de&nbsp;cuves
      </BouttonSection>
    </AnimatedView>
  );
};

export default AccueilView;
