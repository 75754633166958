import React, {
  forwardRef,
  useState,
  useCallback,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from "react";
import { DateTime } from "luxon";
import styled from "styled-components";
import { Icon, Modal } from "semantic-ui-react";
// @ts-ignore
import { GS1Reader } from "gs1js";

import {
  Form,
  Field,
  FieldLabel,
  FieldContent,
  Legend,
  Label,
  Input,
  InputScan,
  InputCalendar,
  InputDate,
  Separator,
  CommandField,
  ValidIcon,
} from "../../../../core/layout/form/form.elements";
import {
  ButtonFormSquare,
  ButtonSubmit,
} from "../../../../core/layout/button/button.elements";
// import ScannerCodebarre from "../../../../core/scanner/codebarre/codebarre.element";
import ScannerCodebarre from "../../../../core/scannerV2/scanner.element";

import { LotBO2 } from "../../../../../interfaces/lotBO2.interface";
import { format } from "date-fns";

interface LotFormProps {
  lotBO2: LotBO2 | null;
  setLotBO2: (lot: LotBO2 | null) => void;
  sCodeBarre: string;
  setCodeBarre: (sCodeBarre: string) => void;
  bValide: boolean | null;
}

export interface LotFormHandles {
  enable(focus?: boolean): void;
  disable(): void;
  reset(): void;
}

const StyledForm = styled(Form)`
  margin: 0 0 10px;
`;

interface ApplicationIdentifier {
  identifier: string;
  value: string;
  length: number;
}

const LotForm: ForwardRefRenderFunction<LotFormHandles, LotFormProps> = (
  { lotBO2, setLotBO2, sCodeBarre, setCodeBarre, bValide },
  ref
) => {
  const [scanner, setScanner] = useState<boolean>(false);
  const [calendar, setCalendar] = useState<boolean>(false);
  const [bCodeBarreLoading, setCodeBarreLoading] = useState<boolean>(false);
  const [numeroDisabled, setNumeroDisabled] = useState<boolean>(false);
  const [dlcDisabled, setDLCDisabled] = useState<boolean>(false);
  // const numeroRef = useRef<HTMLInputElement>(null);
  // const dlcRef = useRef<HTMLInputElement>(null);
  const scannerRef: any = React.createRef();
  const DLCRef: any = React.createRef();
  const [sNumero, setNumero] = useState<string>("");
  const [sDLC, setDLC] = useState<string>("");
  const [bResultat, setResultat] = useState<boolean>(true);

  useImperativeHandle(ref, () => ({
    enable: (focus: boolean = false) => {
      // numeroRef?.current?.removeAttribute("disabled");
      setDLCDisabled(false);
      setNumeroDisabled(false);
      // setCodeBarre("");
      // if (focus) {
      //   scannerRef?.current?.focus();
      // }
    },
    disable: () => {
      // numeroRef?.current?.setAttribute("disabled", "disabled");
      setDLCDisabled(true);
      setNumeroDisabled(true);
      setCodeBarre("");
    },
    reset: () => {
      setCodeBarre("");
      setNumero("");
      setDLC("");
      setDLCDisabled(false);
      setNumeroDisabled(false);
    },
  }));

  const chercheLot = useCallback(async (): Promise<void> => {
    if (sCodeBarre) {
      setCodeBarreLoading(true);
      const reader = new GS1Reader(sCodeBarre);

      const tApplicationIdentifiers: ApplicationIdentifier[] =
        reader.getApplicationIdentifiers();

      const sNumeroLot =
        tApplicationIdentifiers.find((data) => data.identifier === "10") ||
        null;

      const sDLC =
        tApplicationIdentifiers.find((data) => data.identifier === "17") ||
        null;

      if (sNumero !== null && sDLC !== null) {
        setNumero(sNumeroLot?.value || "");

        let dDLC = "";
        if (sDLC.value) {
          dDLC =
            DateTime.fromJSDate(
              new Date(
                `20${sDLC?.value.slice(0, 2)}-${sDLC?.value.slice(
                  2,
                  4
                )}-${sDLC?.value.slice(4, 6)}`
              )
            )
              .setLocale("fr")
              .toLocaleString()
              .replace(/\//g, "-") || "";
        }

        setDLC(dDLC);
        setLotBO2({ sLot: sNumeroLot?.value || "", dLimiteConso: dDLC });
      } else {
        setResultat(false);
        setNumero("");
        setDLC("");
        // setCodeBarre("");
      }

      setCodeBarreLoading(false);
    }
  }, [sCodeBarre, sNumero, setLotBO2]);

  const handleInputChange = (evt: React.FormEvent<HTMLInputElement>): void => {
    const {
      currentTarget: { value },
    } = evt;

    setCodeBarre(value);
  };

  const handleNumeroChange = (evt: React.FormEvent<HTMLInputElement>): void => {
    const {
      currentTarget: { value },
    } = evt;

    setNumero(value);

    if (value !== "" && sDLC !== "") {
      setLotBO2({ sLot: value, dLimiteConso: sDLC });
    } else {
      setLotBO2(null);
    }
  };

  const handleDLCChange = (day: string): void => {
    const dateString = format(new Date(day), "dd-LL-yyyy");
    setDLC(dateString);

    if (sNumero !== "" && day !== "") {
      setLotBO2({ sLot: sNumero, dLimiteConso: dateString });
    } else {
      setLotBO2(null);
    }

    setCalendar(false);
  };

  const openCalendar = (evt: React.MouseEvent<HTMLElement>): void => {
    setCalendar(true);
  };

  return (
    <>
      {scanner && (
        <ScannerCodebarre
          onBarcodeScanned={(res) => {
            const reader = new GS1Reader(res);
            const tApplicationIdentifiers: ApplicationIdentifier[] =
              reader.getApplicationIdentifiers();

            const sNumeroLot =
              tApplicationIdentifiers.find(
                (data) => data.identifier === "10"
              ) || null;

            const sDLC =
              tApplicationIdentifiers.find(
                (data) => data.identifier === "17"
              ) || null;

            if (sNumero !== null && sDLC !== null) {
              setNumero(sNumeroLot?.value || "");

              let dDLC = "";
              if (sDLC.value) {
                dDLC =
                  DateTime.fromJSDate(
                    new Date(
                      `20${sDLC?.value.slice(0, 2)}-${sDLC?.value.slice(
                        2,
                        4
                      )}-${sDLC?.value.slice(4, 6)}`
                    )
                  ).toISO() || "";
              }

              setDLC(dDLC);
              setCodeBarre("");
            } else {
              setResultat(false);
              setNumero("");
              setDLC("");
              setCodeBarre("");
            }
          }}
          onBtnCancelScanClick={() => {
            setScanner(false);
          }}
        />
      )}

      <StyledForm
        onSubmit={(evt: React.FormEvent<HTMLFormElement>) =>
          evt.preventDefault()
        }
      >
        <Legend>
          <ValidIcon status={bValide} name="check circle outline" />
          Informations du lot
        </Legend>

        <Field>
          <FieldContent>
            <InputScan
              ref={scannerRef}
              id="sCodeBarre"
              value={sCodeBarre}
              placeholder="Scannez un code-barres"
              onChange={handleInputChange}
            />
            <ButtonFormSquare
              type="button"
              barcode
              onClick={() => setScanner(true)}
            >
              <Icon name="barcode" />
            </ButtonFormSquare>
            <ButtonFormSquare
              type="button"
              onClick={chercheLot}
              disabled={bCodeBarreLoading}
            >
              {bCodeBarreLoading ? (
                <Icon name="hourglass outline" className="loading" />
              ) : (
                <Icon name="search" />
              )}
            </ButtonFormSquare>
          </FieldContent>
        </Field>

        <Separator />

        <Field className={window.innerWidth > 320 ? "inline" : ""}>
          <FieldLabel>
            <Label>DLC</Label>
          </FieldLabel>
          <FieldContent>
            <InputCalendar
              ref={DLCRef}
              id="sDLCField"
              value={lotBO2?.dLimiteConso || sDLC}
              placeholder="Saisissez une date"
              onChange={handleInputChange}
            />
            <ButtonFormSquare type="button" barcode onClick={openCalendar}>
              <Icon name="calendar" />
            </ButtonFormSquare>
          </FieldContent>
        </Field>

        <Field className={window.innerWidth > 320 ? "inline" : ""}>
          <FieldLabel>
            <Label>Numéro</Label>
          </FieldLabel>
          <FieldContent>
            <Input
              disabled={numeroDisabled}
              placeholder="Saisissez un numéro"
              onChange={handleNumeroChange}
              value={lotBO2?.sLot || sNumero}
            />
          </FieldContent>
        </Field>
      </StyledForm>

      <Modal
        dimmer="inverted"
        open={calendar}
        onClose={() => setCalendar(false)}
      >
        <Modal.Content>
          <Form noShadow>
            <Field>
              <InputDate
                disabled={dlcDisabled}
                onChange={handleDLCChange}
                closeOnClickOutside
                value={lotBO2?.dLimiteConso || sDLC}
              />
            </Field>
          </Form>
        </Modal.Content>
      </Modal>

      <Modal
        dimmer="inverted"
        open={!bResultat}
        onClose={() => setResultat(true)}
      >
        <Modal.Content>
          <Form noShadow>
            <Legend>Aucun résultat...</Legend>
            <p>Cette recherche ne retourne aucun résultat.</p>
            <CommandField>
              <ButtonSubmit onClick={() => setResultat(true)}>OK</ButtonSubmit>
            </CommandField>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default forwardRef(LotForm);
