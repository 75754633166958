import styled from "styled-components";
import { Button } from "../../../layout/button/button.elements";

export const CloseButton = styled(Button)`
  position: fixed;
  right: 20px;
  top: 50px;
  z-index: 111;
  margin: 0;
  padding: 0;
  width: 35px;
  height: 35px;
  font-size: 26px;
  text-align: center;
`;
