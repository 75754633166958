import styled from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled.button`
  margin: 0 10px;
  padding: 0 20px;
  height: 35px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${(props) => props.theme.colors.white};
  background: ${(props) => props.theme.button.primary.background};
  border: 0;
  border-radius: 0.28571429rem;
  cursor: pointer;
  transition: background ease-in-out 0.25s;
  outline: none;
  font-size: 14px;

  &:hover {
    background: ${(props) => props.theme.button.primary.hoverBackground};
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:disabled {
    background: ${(props) => props.theme.button.neutral.disabled};
    cursor: default;
  }
`;

export const ButtonCancel = styled(Button)`
  background: ${(props) => props.theme.button.neutral.background};
  &:hover {
    background: ${(props) => props.theme.button.neutral.hoverBackground};
  }
`;

export const ButtonSubmit = styled(Button)`
  width: 100%;
`;

export const ButtonLink = styled(Link)`
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  text-decoration: none;
  color: inherit;
  background: #ccc;
  border: 0;
  cursor: pointer;
`;

export const ButtonFormSquare = styled(Button)<{
  barcode?: boolean;
  disabled?: boolean;
  lock?: boolean;
}>`
  position: relative;
  top: ${(props) => (props.barcode ? "6px" : "2px")};
  float: ${(props) => (props.lock ? "right" : "none")};
  padding: 0;
  margin: 0 0 0 10px;
  width: 35px;
  height: 35px;
  line-height: 35px;

  & i {
    margin: 0;
    font-size: ${(props) => (props.barcode ? "24px" : "16px")};
  }
`;
