import React, {
  forwardRef,
  useEffect,
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from "react";
import styled from "styled-components";
import axios from "axios";
import { Icon, Modal } from "semantic-ui-react";

import config from "../../../../../config";

import {
  Form,
  Field,
  FieldLabel,
  FieldContent,
  Legend,
  Label,
  Droplist,
  DroplistLock,
  Input,
  InputScan,
  RadioGroup,
  RadioLine,
  Radio,
  CommandField,
  Separator,
  ValidIcon,
} from "../../../../core/layout/form/form.elements";
import {
  ButtonSubmit,
  ButtonCancel,
  ButtonFormSquare,
} from "../../../../core/layout/button/button.elements";
// import ScannerCodebarre from "../../../../core/scanner/codebarre/codebarre.element";
import ScannerCodebarre from "../../../../core/scannerV2/scanner.element";
import {
  Localisation,
  Emplacement,
} from "../../../../../interfaces/localisation.interface";
import { BouteilleO2 } from "../../../../../interfaces/bouteilleO2.interface";
import { ModeleBO2 } from "../../../../../interfaces/modeleBO2.interface";

const StyledForm = styled(Form)`
  margin: 10px 0;
`;

export interface BouteilleFormHandles {
  enableModeles(): void;
  disableModeles(): void;
  enableEmplacements(
    localisation: Localisation,
    iFKProduit: number | null
  ): void;
  disableEmplacements(): void;
  clearResBouteille(): void;
  focus(): void;
}

interface BouteilleFormProps {
  bouteilleO2: BouteilleO2 | null;
  setBouteille: (bouteilleO2: BouteilleO2 | null) => void;
  sCodeBarre: string;
  setCodeBarre: (sCodeBarre: string) => void;
  modele: number;
  setModele: (modele: number) => void;
  setModeleDefaut: (modele: number) => void;
  modeleDefaut: number;
  bDefault: boolean;
  setDefault: (bDefault: boolean) => void;
  localisation: Localisation | null;
  emplacement: Emplacement | null;
  setEmplacement: (emplacement: Emplacement | null) => void;
  bValide: boolean | null;
}

const BouteilleO2Form: ForwardRefRenderFunction<
  BouteilleFormHandles,
  BouteilleFormProps
> = (
  {
    bouteilleO2,
    setBouteille,
    sCodeBarre,
    setCodeBarre,
    modele,
    setModele,
    setModeleDefaut,
    modeleDefaut,
    bDefault,
    setDefault,
    localisation,
    emplacement,
    setEmplacement,
    bValide,
  },
  ref
) => {
  const [bCodeBarreLoading, setCodeBarreLoading] = useState<boolean>(false);
  const [tModele, setModeles] = useState<ModeleBO2[]>([]);
  const [scanner, setScanner] = useState<boolean>(false);
  const scannerRef: any = React.createRef(); // useRef<HTMLSelectElement>(null);
  const [bModelesActive, setModelesActive] = useState<boolean>(true);
  const [tMultiple, setMultiple] = useState<BouteilleO2[]>([]);
  const [tEmplacement, setEmplacements] = useState<Emplacement[]>([]);
  const emplacementsListbox = useRef<HTMLSelectElement>(null);
  const [resBouteille, setResBouteille] = useState<BouteilleO2 | null>(null);

  const chargeModeles = useCallback(async () => {
    try {
      const response = await axios(`${config.apiURL}/mat/dm/bo2/modeles`, {
        method: "GET",
        withCredentials: true,
      }).catch((err: any) => {
        throw new Error(err.response.data.sMessage);
      });

      const tModele = response.data.data;

      setModeles(tModele);
    } catch (err: any) {
      console.error(err.message ?? "Une erreur est survenue");
    }
  }, []);

  const chargeEmplacements = useCallback(
    async (
      localisation: Localisation,
      iFKProduit: number | null
    ): Promise<void> => {
      try {
        const response = await axios(
          `${config.apiURL}/mat/loc/emplacements/cherche`,
          {
            method: "POST",
            data: {
              sNatureLocalisation: localisation.sNatureLocalisation,
              iFKLocalisation: localisation.iPKLocalisation,
              iFKProduit,
            },
            withCredentials: true,
          }
        ).catch((err: any) => {
          throw new Error(err.response.data.sMessage);
        });

        const tResult = response.data.data;

        setEmplacements(tResult);

        if (tResult.length === 1) {
          setEmplacement(tResult[0]);
        }
      } catch (err: any) {
        console.error(err.message ?? "Une erreur est survenue");
        setEmplacements([]);
      }
    },
    [setEmplacement]
  );

  const chercheBouteilleO2 = useCallback(
    async (res: string): Promise<void> => {
      if (sCodeBarre || res) {
        try {
          setCodeBarreLoading(true);
          const response = await axios(`${config.apiURL}/mat/dm/bo2/cherche`, {
            method: "POST",
            data: {
              sCodeBarre: sCodeBarre || res,
            },
            withCredentials: true,
          }).catch((err: any) => {
            throw new Error(err.response.data.sMessage);
          });

          const tBouteille = response.data.data;
          setCodeBarre("");
          if (tBouteille.length === 0) {
            setResBouteille({
              iPKProduitDM: 0,
              iFKProduit: 0,
              sRefAppareil: "",
              sNumeroSerie: sCodeBarre || res,
            });
            // setModele(0);
            if (modeleDefaut === 0) {
              setModele(0);
              setModelesActive(true);
            } else {
              setModele(modeleDefaut);
              setModelesActive(false);
              setDefault(true);
            }
            if (localisation) {
              chargeEmplacements(localisation, null);
            }

            if (localisation && localisation.bEmplacement) {
              setEmplacement(null);
            }
            // setDefault(false);
          } else if (tBouteille.length === 1) {
            // setBouteille(tBouteille[0]);
            setResBouteille(tBouteille[0]);
            if (tBouteille[0].iFKProduit !== modele) {
              setDefault(false);
            } else {
              setDefault(true);
            }

            setModele(tBouteille[0].iFKProduit);

            if (localisation && localisation.bEmplacement) {
              chargeEmplacements(localisation, tBouteille[0].iFKProduit);
            }
          } else if (tBouteille.length > 1) {
            setMultiple(tBouteille);
          }

          setCodeBarreLoading(false);
        } catch (err: any) {
          console.error(err.message ?? "Une erreur est survenue");

          setCodeBarreLoading(false);
        }
      }
    },
    [
      sCodeBarre,
      setCodeBarre,
      localisation,
      setModele,
      modeleDefaut,
      chargeEmplacements,
      setEmplacement,
      modele,
      setDefault,
    ]
  );

  useImperativeHandle(ref, () => ({
    clearResBouteille: () => {
      setResBouteille(null);
    },
    focus: () => {
      // scannerRef?.current?.focus();
    },
    enableModeles: () => {
      setModelesActive(true);
      // modelesListbox?.current?.removeAttribute("disabled");
    },
    disableModeles: () => {
      setModelesActive(false);
      // modelesListbox?.current?.setAttribute("disabled", "disabled");
    },
    enableEmplacements: (
      localisation: Localisation,
      iFKProduit: number | null
    ) => {
      chargeEmplacements(localisation, iFKProduit);
    },
    disableEmplacements: () => {
      setEmplacement(null);
      setEmplacements([]);
    },
  }));

  useEffect(() => {
    chargeModeles();
  }, [chargeModeles]);

  useEffect(() => {
    setBouteille(resBouteille);
  }, [resBouteille, setBouteille]);

  const handleSelectChange = (
    evt: React.FormEvent<HTMLSelectElement>,
    data: any
  ): void => {
    if (parseInt(data.value, 10) !== -1) {
      setModele(data.value || 0);
    }
  };

  const handleInputChange = (evt: React.FormEvent<HTMLInputElement>): void => {
    const {
      currentTarget: { value },
    } = evt;

    setCodeBarre(value);
  };

  const handleCheckboxChange = (
    evt: React.FormEvent<HTMLButtonElement>
  ): void => {
    if (modele !== 0) {
      if (!bDefault) {
        setModeleDefaut(modele);
        setModelesActive(false);
        // modelesListbox?.current?.setAttribute("disabled", "disabled");
      } else {
        setModeleDefaut(0);
        setModelesActive(true);
        // modelesListbox?.current?.removeAttribute("disabled");
      }
      setDefault(!bDefault);
    }
  };

  const handleEmplacementChange = (
    evt: React.FormEvent<HTMLSelectElement>,
    data: any
  ): void => {
    // const {
    //   currentTarget: { value },
    // } = evt;

    const selectedEmplacement = tEmplacement.find(
      (e: Emplacement) => e.iPKEmplacement === parseInt(data.value, 10)
    );

    setEmplacement(selectedEmplacement || null);
  };

  const tEmplacementOption: any[] = [
    { key: -1, text: "Sélectionnez un emplacement", value: -1 },
  ];
  tEmplacement.forEach((e) => {
    tEmplacementOption.push({
      key: e.iPKEmplacement,
      text: e.sCodeEmplacement,
      value: e.iPKEmplacement,
    });
  });

  const tModeleOption: any[] = [
    { key: -1, text: "Sélectionnez un modèle", value: -1 },
  ];
  tModele.forEach((m) => {
    tModeleOption.push({
      key: m.iPKProduit,
      text: m.sLibelle,
      value: m.iPKProduit,
    });
  });

  return (
    <>
      {scanner && (
        <ScannerCodebarre
          onBarcodeScanned={(res) => {
            setCodeBarre(res);
            chercheBouteilleO2(res);
            setScanner(false);
          }}
          onBtnCancelScanClick={() => {
            setScanner(false);
          }}
        />
      )}

      <StyledForm
        onSubmit={(evt: React.FormEvent<HTMLFormElement>) =>
          evt.preventDefault()
        }
      >
        <Legend>
          <ValidIcon status={bValide} name="check circle outline" />
          Informations de la bouteille
        </Legend>

        <Field>
          <FieldContent>
            <InputScan
              ref={scannerRef}
              id="sCodeBarre"
              value={sCodeBarre}
              placeholder="Scannez un code-barres"
              onChange={handleInputChange}
            />
            <ButtonFormSquare
              type="button"
              barcode
              onClick={() => setScanner(true)}
            >
              <Icon name="barcode" />
            </ButtonFormSquare>
            <ButtonFormSquare
              type="button"
              onClick={() => chercheBouteilleO2(sCodeBarre)}
              disabled={bCodeBarreLoading}
            >
              {bCodeBarreLoading ? (
                <Icon name="hourglass outline" className="loading" />
              ) : (
                <Icon name="search" />
              )}
            </ButtonFormSquare>
          </FieldContent>
        </Field>

        <Separator />

        <Field className={window.innerWidth > 320 ? "inline" : ""}>
          <FieldLabel select>
            <Label>Modèle</Label>
          </FieldLabel>
          <FieldContent>
            <DroplistLock
              clearable
              options={tModeleOption}
              selection
              placeholder="Sélectionnez un modèle"
              onChange={handleSelectChange}
              disabled={!bModelesActive}
              value={modele || 0}
            />
            <ButtonFormSquare lock type="button" onClick={handleCheckboxChange}>
              <Icon name={bDefault ? "lock" : "lock open"} />
            </ButtonFormSquare>
          </FieldContent>
        </Field>

        <Field className={window.innerWidth > 320 ? "inline" : ""}>
          <FieldLabel>
            <Label>Réf. app.</Label>
          </FieldLabel>
          <FieldContent>
            <Input value={bouteilleO2?.sRefAppareil || ""} disabled />
          </FieldContent>
        </Field>

        <Field className={window.innerWidth > 320 ? "inline" : ""}>
          <FieldLabel>
            <Label>N/S</Label>
          </FieldLabel>
          <FieldContent>
            <Input value={bouteilleO2?.sNumeroSerie || ""} disabled />
          </FieldContent>
        </Field>

        {tEmplacementOption.length > 1 && (
          <Field className={window.innerWidth > 320 ? "inline" : ""}>
            <FieldLabel>
              <Label>Emplacement</Label>
            </FieldLabel>
            <FieldContent>
              <Droplist
                fluid
                clearable
                options={tEmplacementOption}
                selection
                placeholder="Sélectionnez un emplacement"
                onChange={handleEmplacementChange}
                ref={emplacementsListbox}
                disabled={tEmplacement.length === 1}
                value={emplacement?.iPKEmplacement || 0}
              />
            </FieldContent>
          </Field>
        )}
      </StyledForm>

      <Modal
        dimmer="inverted"
        open={tMultiple.length > 0}
        onClose={() => setMultiple([])}
      >
        <Modal.Content>
          <Form noShadow>
            <Legend>Plusieurs résultats trouvés !</Legend>

            <Field className="radios-group">
              <Label>Sélectionnez la bouteille correspondant :</Label>
              <RadioGroup>
                {tMultiple.map((bO2) => (
                  <RadioLine key={bO2.iFKProduit}>
                    <Radio
                      type="radio"
                      name="BO2"
                      id={`${bO2.iFKProduit}`}
                      onClick={() => {
                        setBouteille(bO2);
                        setModele(bO2.iFKProduit);
                        setDefault(false);
                      }}
                    />
                    <Label htmlFor={`${bO2.iFKProduit}`}>
                      {bO2.sLibelleModele}
                    </Label>
                  </RadioLine>
                ))}
              </RadioGroup>
            </Field>

            <CommandField>
              <ButtonSubmit onClick={() => setMultiple([])}>
                Valider
              </ButtonSubmit>
              <ButtonCancel onClick={() => setMultiple([])}>
                Annuler
              </ButtonCancel>
            </CommandField>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default forwardRef(BouteilleO2Form);
