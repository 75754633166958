import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

import { selectConnecte } from "../../../../redux/utilisateur/utilisateur.slice";

import AnimatedView from "../../../core/layout/view/view.elements";
import ConenxionForm from "./connexion.form";
import {
  Logo,
  AppName,
  AppNameEasy,
  AppNameStock,
  ConnexionFormWrapper,
  Version,
} from "./connexion.elements";

import config from "../../../../config";

import logoSVG from "../../../../ui/assets/img/logo.svg";

const ConnexionView: FunctionComponent = () => {
  const bConnecte = useSelector(selectConnecte);

  if (bConnecte) {
    return <Redirect to="/accueil" />;
  }

  return (
    <AnimatedView view="Connexion">
      <Logo src={logoSVG} alt="" />
      <AppName>
        <AppNameEasy>easy</AppNameEasy>
        <AppNameStock>stock</AppNameStock>
      </AppName>
      <ConnexionFormWrapper>
        <ConenxionForm />
      </ConnexionFormWrapper>
      <Version>version{config.version}</Version>
    </AnimatedView>
  );
};

export default ConnexionView;
