import React, { FunctionComponent } from "react";

import AnimatedView from "../../core/layout/view/view.elements";
import RemplissageCuvesForm from "./form/cuves.form";

const BouteilleO2View: FunctionComponent = () => {
  return (
    <AnimatedView view="Remplissage de cuves">
      <RemplissageCuvesForm />
    </AnimatedView>
  );
};

export default BouteilleO2View;
