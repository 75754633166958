import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import axios from "axios";

import App from "./App";
import store from "./store";
import * as serviceWorker from "./serviceWorker";

import {
  connexionSucces,
  connexionErreur,
} from "./redux/utilisateur/utilisateur.slice";

import config from "./config";
import React from "react";

axios.defaults.headers["Cache-Control"] = "no-cache";
axios.defaults.headers["Pragma"] = "no-cache";

function getCookie(name: string) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
}

const authorization = getCookie("Authorization");

if (authorization) {
  axios(`${config.apiURL}/auth/verify`, {
    method: "POST",
    data: {},
    withCredentials: true,
  })
    .then((response) => {
      const {
        data: { user },
      } = response;

      if (user) {
        store.dispatch(connexionSucces(user));
      }
    })
    .catch((err: any) => {
      store.dispatch(connexionErreur({ sErreur: "Session invalide." }));
    });
}

const element = document.getElementById("root");

if (element) {
  const root = ReactDOM.createRoot(element);

  root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}
