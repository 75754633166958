import styled from "styled-components";
import { ButtonLink } from "../../core/layout/button/button.elements";

export const Logo = styled.img`
  display: block;
  margin: 40px auto 0;
  width: 50%;
  height: auto;
  animation: App-logo-float infinite 3s ease-in-out;
`;

export const AppName = styled.h1`
  margin: 40px 0;
  height: 30px;
  font-family: "UniversCondensedBold";
  font-size: 60px;
  text-align: center;
`;

export const AppNameEasy = styled.span`
  font-family: "UniversCondensed", sans-serif !important;
  font-weight: normal;
`;

export const AppNameStock = styled.span`
  font-weight: bold;
`;

export const BouttonConnexion = styled(ButtonLink)`
  display: block;
  margin: 0 auto !important;
  width: 50% !important;
`;

export const Version = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  font-size: 12px;
  opacity: 0.5;
  cursor: default;
`;
