import React, { FunctionComponent, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BouttonConnexion } from "./deconnexion.elements";

import { deconnexionAction } from "../../../../redux/utilisateur/actions/deconnexion.action";

import AnimatedView from "../../../core/layout/view/view.elements";

const DeconnexionView: FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(deconnexionAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatedView view="Déconnexion">
      <p>Vous êtes déconnecté !</p>

      <BouttonConnexion to="/connexion">Connexion</BouttonConnexion>
    </AnimatedView>
  );
};

export default DeconnexionView;
