import React, { FunctionComponent } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectConnecte } from "../../../redux/utilisateur/utilisateur.slice";

import AnimatedView from "../../core/layout/view/view.elements";
import {
  Logo,
  AppName,
  AppNameEasy,
  AppNameStock,
  BouttonConnexion,
  Version,
} from "./index.elements";

import config from "../../../config";

import logoSVG from "../../../ui/assets/img/logo.svg";

const IndexView: FunctionComponent = () => {
  const bConnecte = useSelector(selectConnecte);

  if (bConnecte) {
    return <Redirect to="/accueil" />;
  }

  return (
    <AnimatedView view="Accueil">
      <Logo src={logoSVG} alt="" />
      <AppName>
        <AppNameEasy>easy</AppNameEasy>
        <AppNameStock>stock</AppNameStock>
      </AppName>
      <BouttonConnexion to="/connexion">Connexion</BouttonConnexion>
      <Version>version {config.version}</Version>
    </AnimatedView>
  );
};

export default IndexView;
