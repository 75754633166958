import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import styled from "styled-components";
import { Icon, Modal } from "semantic-ui-react";
import axios from "axios";

import {
  Form,
  Field,
  FieldContent,
  Legend,
  Label,
  InputScan,
  Radio,
  RadioGroup,
  RadioSegment,
  RadioLine,
  Droplist,
  ValidIcon,
  CommandField,
  Separator,
} from "../../../../core/layout/form/form.elements";
import {
  ButtonSubmit,
  ButtonCancel,
  ButtonFormSquare,
} from "../../../../core/layout/button/button.elements";
import ScannerCodebarre from "../../../../core/scannerV2/scanner.element";

import { MatOxyAppareil } from "../../../../../interfaces/matoxy.interface";

import config from "../../../../../config";

import mp3OK from "../../../../../ui/assets/snd/beep-ok.mp3";
import mp3KO from "../../../../../ui/assets/snd/beep-ko.mp3";

const StyledForm = styled(Form)`
  margin: 15px 0;
`;

const DestinationsListe = styled.div`
  padding: 5px 0 0;
`;

const Destination = styled.div`
  margin: 0 0 5px;
  padding: 0 0 5px;
  min-height: 40px;
  border-bottom: 1px solid ${(props) => props.theme.border.light};

  &:last-child {
    border: 0;
  }
`;

const DestinationLibelle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 40px;
  line-height: 20px;
  font-size: 14px;
`;

const ButtonDestinationDelete = styled(ButtonFormSquare)`
  float: right;
  top: 0;
  font-size: 18px;
  font-weight: bold;
`;

const DestinationRadioSegment = styled(RadioSegment)`
  margin: 0;
  width: 50%;
`;

const ScrollPanel = styled.div`
  max-height: ${window.innerHeight - 200}px;
  overflow-y: auto;
`;

const ResultOK = styled.div<{ iResultat: number }>`
  position: fixed;
  z-index: 10000;
  left: 0;
  bottom: ${(props) => (props.iResultat !== 1 ? "-60px" : "0px")};
  height: 60px;
  width: 100%;
  line-height: 60px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  background: ${(props) => props.theme.colors.valid.main};
  transition: bottom 0.5s ease-in-out;
`;
const ResultSOSO = styled.div<{ iResultat: number }>`
  position: fixed;
  z-index: 10000;
  left: 0;
  bottom: ${(props) => (props.iResultat !== 2 ? "-60px" : "0px")};
  height: 60px;
  width: 100%;
  line-height: 60px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  background: ${(props) => props.theme.colors.neutral.main};
  transition: bottom 0.5s ease-in-out;
`;
const ResultKO = styled.div<{ iResultat: number }>`
  position: fixed;
  z-index: 10000;
  left: 0;
  bottom: ${(props) => (props.iResultat !== -1 ? "-60px" : "0px")};
  height: 60px;
  width: 100%;
  line-height: 60px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  background: ${(props) => props.theme.colors.error.main};
  transition: bottom 0.5s ease-in-out;
`;

interface DestinationsFormProps {
  tAppareil: MatOxyAppareil[];
  setAppareils: (tAppareil: MatOxyAppareil[]) => void;
  disabled: boolean;
  sCodeBarreCuve: string;
  setCodeBarreCuve: (sCodeBarreCuve: string) => void;
  bValide: boolean | null;
}

const DestinationsForm: FunctionComponent<DestinationsFormProps> = ({
  tAppareil,
  setAppareils,
  disabled,
  sCodeBarreCuve,
  setCodeBarreCuve,
  bValide,
}) => {
  const [soundOK] = useState(new Audio(mp3OK));
  const [soundKO] = useState(new Audio(mp3KO));
  const [scanner, setScanner] = useState<boolean>(false);
  const [sType, setType] = useState<string>("CUVE");
  const [bResultat, setResultat] = useState<boolean>(true);
  const [iResultatMessage, setResultatMessage] = useState<number>(0);
  const [tMultiple, setMultiple] = useState<MatOxyAppareil[]>([]);
  const [iSelection, setSelection] = useState<number>(0);
  const [tVehicule, setVehicules] = useState<MatOxyAppareil[]>([]);
  const [tVehiculeOption, setVehiculeOptions] = useState<any[]>([]);
  const [cuve, setCuve] = useState<number>(0);
  const [multipleCuves, setMultipleCuves] = useState<MatOxyAppareil | null>(
    null
  );
  const debounceTimeoutRef = useRef<number | NodeJS.Timeout | undefined>(
    undefined
  );
  const sDernierCodeBarre = useRef<string>("");

  const beep = useCallback(
    (type: string, duration: number = 0.125): void => {
      let sound;

      if (type === "OK") {
        sound = soundOK;
        navigator.vibrate(100);
      } else {
        sound = soundKO;
        navigator.vibrate([100, 30, 100, 30, 100]);
      }

      sound.play();
    },
    [soundOK, soundKO]
  );

  const handleOrigineTypeChange = (
    evt: React.FormEvent<HTMLInputElement>
  ): void => {
    const {
      currentTarget: { value },
    } = evt;

    setType(value);
  };

  const chargeVehicules = useCallback(async () => {
    try {
      const response = await axios(`${config.apiURL}/mat/dm/oxy/appareils`, {
        method: "POST",
        data: {
          sType: "VEH",
        },
        withCredentials: true,
      }).catch((err: any) => {
        throw new Error(err.response.data.sMessage);
      });

      setVehicules(response.data.data);
    } catch (err: any) {
      console.error(err.message ?? "Une erreur est survenue");
    }
  }, []);

  const handleVehiculeChange = (
    evt: React.FormEvent<HTMLSelectElement>,
    data: any
  ): void => {
    evt.preventDefault();
    if (parseInt(data.value, 10) !== -1) {
      const newVehicule = tVehicule.find(
        (v) => v.iPKProduitDM === parseInt(data.value || 0, 10)
      );

      if (newVehicule) {
        if (newVehicule.iNbCuve > 1) {
          setMultipleCuves(newVehicule);
        } else {
          if (
            !tAppareil.find((a) => a.iPKProduitDM === newVehicule.iPKProduitDM)
          ) {
            setAppareils([
              ...tAppareil,
              Object.assign({}, newVehicule, { iNumeroCuve: 1 }),
            ]);
          }
          setCodeBarreCuve("");
        }
      }
    }
  };

  const showMessage = (resultatMessage: number) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    setResultatMessage(resultatMessage);

    debounceTimeoutRef.current = setTimeout(() => setResultatMessage(0), 2000);
  };

  const hideMessage = () => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    setResultatMessage(0);
  };

  const requestAPI = useCallback(
    async (sCodeBarre) => {
      try {
        const response = await axios(`${config.apiURL}/mat/dm/oxy/appareils`, {
          method: "POST",
          data: {
            sType,
            sCodeBarre: sCodeBarre,
          },
          withCredentials: true,
        });

        return response.data.data;
      } catch (err: any) {
        throw new Error(err.response.data.sMessage);
      }
    },
    [sType]
  );

  const scanChercheAppareil = useCallback(
    async (res: string): Promise<void> => {
      if (res.length < 1) {
        return;
      } else if (res !== sDernierCodeBarre.current) {
        try {
          sDernierCodeBarre.current = res;
          const apiResult = await requestAPI(res);
          const tAppareilResult = apiResult.filter(
            (resApp) =>
              !tAppareil.some(
                (listApp) => listApp.iPKProduitDM === resApp.iPKProduitDM
              )
          );

          if (tAppareilResult.length === 0) {
            if (apiResult.length > 0) {
              beep("KO");
              showMessage(2);
              focusScanField();
              setCodeBarreCuve("");
            } else {
              beep("KO");
              focusScanField();
              showMessage(-1);
              setResultat(false);
              sDernierCodeBarre.current = "";
              setCodeBarreCuve(res);
            }
          } else if (tAppareilResult.length === 1) {
            beep("OK");
            showMessage(1);
            focusScanField();
            setAppareils([...tAppareil, tAppareilResult[0]]);
            setCodeBarreCuve("");
          } else if (tAppareilResult.length > 1) {
            setMultiple(tAppareilResult);
            setCodeBarreCuve("");
          }
        } catch (err: any) {
          beep("KO");
          showMessage(-1);
          focusScanField();
          setCodeBarreCuve("");
          console.error(err.message ?? "Une erreur est survenue");
        }
      }
    },
    [
      beep,
      requestAPI,
      tAppareil,
      setCodeBarreCuve,
      setAppareils,
      sDernierCodeBarre,
    ]
  );

  const saisieChercheAppareil = useCallback(
    async (sCodeBarre: string): Promise<void> => {
      if (sCodeBarre.length > 0) {
        try {
          const apiResult = await requestAPI(sCodeBarre);
          const tAppareilResult = apiResult.filter(
            (resApp) =>
              !tAppareil.some(
                (listApp) => listApp.iPKProduitDM === resApp.iPKProduitDM
              )
          );

          focusScanField();

          if (tAppareilResult.length === 0) {
            if (apiResult.length > 0) {
              showMessage(2);
            } else {
              focusScanField();
              setResultat(false);
            }
          } else if (tAppareilResult.length === 1) {
            showMessage(1);
            setAppareils([...tAppareil, tAppareilResult[0]]);
            setCodeBarreCuve("");
          } else if (tAppareilResult.length > 1) {
            setMultiple(tAppareilResult);
            setCodeBarreCuve("");
          }
        } catch (err: any) {
          showMessage(-1);
          console.error(err.message ?? "Une erreur est survenue");
        }
      }
    },
    [requestAPI, tAppareil, setCodeBarreCuve, setAppareils]
  );

  const handleCodeBarreChange = (
    evt: React.FormEvent<HTMLInputElement>
  ): void => {
    const {
      currentTarget: { value },
    } = evt;

    if (value.length - sCodeBarreCuve.length > 4) {
      saisieChercheAppareil(value);
    }

    setCodeBarreCuve(value);
  };

  const handleAppareilRemove = (
    iPKProduitDM: number,
    iNumeroCuve: number | undefined
  ): void => {
    if (iNumeroCuve) {
      setAppareils(
        tAppareil.filter(
          (a) =>
            a.iPKProduitDM !== iPKProduitDM ||
            (a.iPKProduitDM === iPKProduitDM && a.iNumeroCuve !== iNumeroCuve)
        )
      );
    } else {
      setAppareils(tAppareil.filter((a) => a.iPKProduitDM !== iPKProduitDM));
    }
  };

  const focusScanField = () => {
    const codeBarreInputEl: HTMLInputElement | null =
      document.querySelector("#sCodeBarre");

    if (codeBarreInputEl) {
      codeBarreInputEl.focus();

      codeBarreInputEl.select();
    }
  };

  useEffect(() => {
    if (!disabled && sType === "CUVE") {
      focusScanField();
    }
  }, [disabled, sType]);

  useEffect(() => {
    chargeVehicules();
  }, [chargeVehicules]);

  useEffect(() => {
    const tOption: any[] = [
      { key: -1, text: "Sélectionnez un véhicule", value: -1 },
    ];

    tVehicule.forEach((v) => {
      if (
        !(
          tAppareil.find(
            (a) => a.iPKProduitDM === v.iPKProduitDM && a.iNumeroCuve === 1
          ) &&
          tAppareil.find(
            (a) => a.iPKProduitDM === v.iPKProduitDM && a.iNumeroCuve === 2
          )
        ) &&
        !tAppareil.find(
          (a) => a.iPKProduitDM === v.iPKProduitDM && a.iNbCuve === 1
        )
      ) {
        tOption.push({
          key: v.iPKProduitDM,
          text: `${v.sLibelleModele} - ${v.sRefProduit}`,
          value: v.iPKProduitDM,
        });
      }
    });
    setVehiculeOptions(tOption);
  }, [tAppareil, tVehicule]);

  useEffect(() => {
    if (!scanner) {
      sDernierCodeBarre.current = "";
    }
  }, [scanner]);

  return (
    <>
      {scanner && (
        <ScannerCodebarre
          onBarcodeScanned={(res) => {
            // setCodeBarreCuve(res);
            scanChercheAppareil(res);
          }}
          onBtnCancelScanClick={() => {
            hideMessage();
            setScanner(false);
          }}
        />
      )}

      <StyledForm>
        <Legend>
          <ValidIcon status={bValide} name="check circle outline" />
          Destinations
        </Legend>

        <Field className="inline radios">
          <RadioGroup>
            <DestinationRadioSegment>
              <Radio
                type="radio"
                id="cuve"
                name="sType"
                value="CUVE"
                disabled={disabled}
                checked={sType === "CUVE"}
                onClick={handleOrigineTypeChange}
              />
              <Label htmlFor="cuve" active={sType === "CUVE"}>
                Cuve
              </Label>
            </DestinationRadioSegment>
            <DestinationRadioSegment>
              <Radio
                type="radio"
                id="vehicule"
                name="sType"
                value="VEH"
                disabled={disabled}
                checked={sType === "VEH"}
                onClick={handleOrigineTypeChange}
              />
              <Label htmlFor="vehicule" active={sType === "VEH"}>
                Véhicule
              </Label>
            </DestinationRadioSegment>
          </RadioGroup>
        </Field>
        <Separator />

        {sType === "CUVE" && (
          <Field>
            <FieldContent>
              <InputScan
                style={{ float: "left" }}
                id="sCodeBarre"
                placeholder="Scannez un code-barres"
                value={sCodeBarreCuve}
                onChange={handleCodeBarreChange}
                disabled={disabled}
              />
              <ButtonFormSquare
                style={{ position: "absolute", top: 0 }}
                type="button"
                barcode
                onClick={() => setScanner(true)}
                disabled={disabled}
              >
                <Icon name="barcode" />
              </ButtonFormSquare>
              <ButtonFormSquare
                style={{ position: "absolute", top: 0, right: 0 }}
                type="button"
                onClick={() => saisieChercheAppareil(sCodeBarreCuve)}
                disabled={disabled}
              >
                <Icon name="search" />
              </ButtonFormSquare>
            </FieldContent>
          </Field>
        )}

        {sType === "VEH" && (
          <Field>
            <FieldContent>
              <Droplist
                fluid
                clearable
                options={tVehiculeOption}
                selection
                placeholder="Sélectionnez un véhicule"
                onChange={handleVehiculeChange}
                value={0}
                disabled={disabled}
              />
            </FieldContent>
          </Field>
        )}

        <Separator />

        <DestinationsListe>
          {tAppareil.map((appareil) => (
            <Destination key={appareil.iPKProduitDM}>
              <ButtonDestinationDelete
                type="button"
                onClick={() => {
                  handleAppareilRemove(
                    appareil.iPKProduitDM,
                    appareil.iNumeroCuve
                  );
                }}
              >
                ✕
              </ButtonDestinationDelete>
              <DestinationLibelle>
                {`${appareil.sLibelleModele} - ${appareil.sRefProduit} ${
                  appareil.iNbCuve > 1
                    ? ` (cuve n°${appareil.iNumeroCuve})`
                    : ""
                }`}
              </DestinationLibelle>
            </Destination>
          ))}
        </DestinationsListe>
      </StyledForm>

      <Modal
        dimmer="inverted"
        open={tMultiple.length > 0}
        onClose={() => setMultiple([])}
      >
        <Modal.Content>
          <Form noShadow>
            <Legend>Plusieurs résultats trouvés !</Legend>

            <Field className="radios-group">
              <Label>Sélectionnez l'appareil correspondant :</Label>
              <ScrollPanel>
                <RadioGroup>
                  {tMultiple.map((appareil) => (
                    <RadioLine key={appareil.iPKProduitDM}>
                      <Radio
                        type="radio"
                        name="BO2"
                        id={`${appareil.iPKProduitDM}`}
                        onClick={() => {
                          setSelection(appareil.iPKProduitDM);
                        }}
                      />
                      <Label htmlFor={`${appareil.iPKProduitDM}`}>
                        {appareil.sLibelleModele}
                        <div style={{ fontSize: "14px" }}>
                          Réf. : {appareil.sRefProduit} - N/S :{" "}
                          {appareil.sRefProduit}
                        </div>
                      </Label>
                    </RadioLine>
                  ))}
                </RadioGroup>
              </ScrollPanel>
            </Field>

            <CommandField>
              <ButtonSubmit
                onClick={() => {
                  const selection = tMultiple.find(
                    (appareil) => appareil.iPKProduitDM === iSelection
                  );
                  if (selection) {
                    if (
                      !tAppareil.find(
                        (a) => a.iPKProduitDM === selection.iPKProduitDM
                      )
                    ) {
                      setAppareils([...tAppareil, selection]);
                    }
                    setCodeBarreCuve("");
                  }
                  setMultiple([]);
                }}
              >
                Valider
              </ButtonSubmit>
              <ButtonCancel onClick={() => setMultiple([])}>
                Annuler
              </ButtonCancel>
            </CommandField>
          </Form>
        </Modal.Content>
      </Modal>

      <Modal
        dimmer="inverted"
        open={multipleCuves !== null}
        onClose={() => setMultipleCuves(null)}
      >
        <Modal.Content>
          <Form noShadow>
            <Legend>Ce véhicule possède plusieurs cuves !</Legend>

            <Field className="radios-group">
              <Label>Sélectionnez une cuve :</Label>
              <RadioGroup>
                {!tAppareil.find(
                  (a) =>
                    a.iPKProduitDM === multipleCuves?.iPKProduitDM &&
                    a.iNumeroCuve === 1
                ) ? (
                  <RadioLine>
                    <Radio
                      type="radio"
                      name="cuve"
                      id="cuve1"
                      onClick={() => {
                        setCuve(1);
                      }}
                    />
                    <Label htmlFor="cuve1">Cuve n°1</Label>
                  </RadioLine>
                ) : (
                  <RadioLine>
                    <Radio type="radio" disabled />
                    <Label htmlFor="cuve1">
                      La cuve n°1 a déjà été sélectionnée
                    </Label>
                  </RadioLine>
                )}
                {!tAppareil.find(
                  (a) =>
                    a.iPKProduitDM === multipleCuves?.iPKProduitDM &&
                    a.iNumeroCuve === 2
                ) ? (
                  <RadioLine>
                    <Radio
                      type="radio"
                      name="cuve"
                      id="cuve2"
                      onClick={() => {
                        setCuve(2);
                      }}
                    />
                    <Label htmlFor="cuve2">Cuve n°2</Label>
                  </RadioLine>
                ) : (
                  <RadioLine>
                    <Radio type="radio" disabled />
                    <Label htmlFor="cuve1">
                      La cuve n°2 a déjà été sélectionnée
                    </Label>
                  </RadioLine>
                )}
              </RadioGroup>
            </Field>

            <CommandField>
              <ButtonSubmit
                onClick={() => {
                  if (multipleCuves) {
                    if (
                      !tAppareil.find(
                        (a) =>
                          a.iFKProduit === multipleCuves.iFKProduit &&
                          a.iNumeroCuve === cuve
                      )
                    ) {
                      setAppareils([
                        ...tAppareil,
                        Object.assign({}, multipleCuves, {
                          iNumeroCuve: cuve,
                        }),
                      ]);
                    }
                    setCodeBarreCuve("");

                    setMultipleCuves(null);
                  }
                }}
              >
                Valider
              </ButtonSubmit>
              <ButtonCancel onClick={() => setMultipleCuves(null)}>
                Annuler
              </ButtonCancel>
            </CommandField>
          </Form>
        </Modal.Content>
      </Modal>

      <Modal
        dimmer="inverted"
        open={!bResultat}
        onClose={() => setResultat(true)}
      >
        <Modal.Content>
          <Form noShadow>
            <Legend>Aucun résultat...</Legend>
            <p>Cette recherche ne retourne aucun résultat.</p>
            <CommandField>
              <ButtonSubmit
                onClick={() => {
                  setResultat(true);
                  focusScanField();
                }}
              >
                OK
              </ButtonSubmit>
            </CommandField>
          </Form>
        </Modal.Content>
      </Modal>

      <ResultOK iResultat={iResultatMessage}>
        Le mouvement vers la cuve a été ajouté !
      </ResultOK>

      <ResultSOSO iResultat={iResultatMessage}>
        Le mouvement vers la cuve a déjà été ajouté !
      </ResultSOSO>

      <ResultKO iResultat={iResultatMessage}>
        La cuve scannée est inconnue...
      </ResultKO>
    </>
  );
};

export default DestinationsForm;
