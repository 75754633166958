import { DayPicker } from "react-day-picker";
import { fr } from "date-fns/locale";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";

interface DateFieldProps {
  value: string;
  onChange: any;
  closeOnClickOutside?: boolean;
  disabled?: boolean;
}

export const DateField = ({ value, onChange, disabled }: DateFieldProps) => {
  let day = new Date();

  if (value.length > 0) {
    const chunks = value.split("-");

    day = new Date(
      format(new Date(`${chunks[2]}-${chunks[1]}-${chunks[0]}`), "yyyy-LL-dd")
    );
  }

  return (
    <DayPicker
      showOutsideDays
      locale={fr}
      selected={day}
      onDayClick={onChange}
      modifiersClassNames={{
        selected: "easystock-selected",
        today: "easystock-today",
      }}
      disabled={disabled}
    />
  );
};
