import React, { FunctionComponent } from "react";

import AnimatedView from "../../core/layout/view/view.elements";
import ReceptionBouteilleO2Form from "./form/bouteilleO2.form";

const BouteilleO2View: FunctionComponent = () => {
  return (
    <AnimatedView view="Réception de bouteilles O2">
      <ReceptionBouteilleO2Form />
    </AnimatedView>
  );
};

export default BouteilleO2View;
