import styled from "styled-components";
import { DateField } from "../calendar/calendar.elements";
import { Dropdown as DropdownSelect, Icon } from "semantic-ui-react";

export const Form = styled.form<{ noShadow?: boolean }>`
  display: block;
  padding: 10px;
  background: rgba(255, 255, 255, 0.75);
  border: 1px solid ${(props) => props.theme.border.light};
  border-radius: 0.28571429rem;
  box-shadow: ${(props) =>
    props.noShadow ? "none" : "0px 0px 10px rgba(0, 0, 0, 0.1)"};

  .ui .modal & {
    margin: 0;
    padding: 0;
    border: 0;
  }
`;

export const Field = styled.div`
  margin: 0 0 10px;

  &.inline {
    display: flex;
    justify-content: center;
  }

  &::last-child {
    margin: 0;
  }
`;

export const FieldLabel = styled.div<{ select?: boolean }>`
  padding: 0 0 5px;

  .inline & {
    flex: 1;
    padding-top: 10px;
  }

  .inline.checkboxes-group &,
  .inline.radios-group & {
    padding-top: 2px;
  }

  & label {
    display: block;
    /* margin-top: ${(props) => (props.select ? "5px" : "0")}; */
  }
`;

export const FieldContent = styled.div`
  position: relative;
  min-height: 35px;

  .inline & {
    flex: 4;
  }

  & > select {
    margin-top: 5px;
  }
`;

export const CommandField = styled.div`
  display: flex;
  margin: 0 -10px -10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-top: 1px solid ${(props) => props.theme.border.light};

  .ui .modal & {
    clear: both;
    margin: 0;
    padding: 15px 0 0;
    border: 0;
  }
`;

export const Legend = styled.div`
  position: relative;
  margin: 40px 0 10px;
  padding: 0 0 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary.main};
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.main};

  &:first-child {
    margin: 0 0 10px;
  }
`;

export const Label = styled.label<{ active?: boolean }>`
  font-weight: bold;
  font-family: "UniversCondensed";
  font-size: 16px;
  /* color: ${(props) =>
    props.active ? props.theme.colors.primary.main : ""}; */

  .inline.checkboxes-group div div &,
  .radios-group div div & {
    position: relative;
    top: 3px;
    float: left;
    display: inline-block;
    padding: 0 0 0 5px;
    width: calc(100% - 30px);
    flex: none;
    font-weight: normal;
    font-family: "UniversCondensed" !important;
    font-size: 14px;
  }

  .inline.checkbox div div &,
  .inline.radios div div & {
    display: block;
    margin: 0px 0 0 30px;
    padding: 0;
    width: calc(100% - 30px);
    flex: none;
    font-weight: normal;
    font-family: "UniversCondensed" !important;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Input = styled.input`
  padding: 0 10px;
  height: 35px;
  width: 100%;
  line-height: 35px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.border.light};
  border-radius: 5px;
  font-size: 14px;
  font-family: "UniversCondensed" !important;
  color: ${(props) => props.theme.text.primary};

  &.code-postal {
    width: 110px;
    text-align: center;
  }

  &:disabled {
    background: transparent;
    color: rgba(0, 0, 0, 0.5);
    cursor: default;
  }
`;

export const InputScan = styled(Input)`
  width: calc(100% - 90px);
`;

export const InputCalendar = styled(Input)`
  width: calc(100% - 45px);
`;

export const InputDate = styled(DateField)`
  width: calc(100% - 50px);
  font-family: "UniversCondensed" !important;
  font-size: 14px;
  border: 1px solid red;
`;

export const Select = styled.select`
  padding: 0 5px;
  width: 100%;
  height: 35px;
  line-height: 35px;
  font-family: "UniversCondensed" !important;
  font-size: 14px;
  border: 1px solid ${(props) => props.theme.border.light};
  cursor: pointer;

  &:disabled {
    background: transparent;
    cursor: default;
  }
`;

export const Droplist = styled(DropdownSelect)`
  width: 100%;
  min-height: 35px;
  font-family: "UniversCondensed" !important;
  font-size: 14px;

  & .ui.dropdown:not(.button) > .default.text {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  @media only screen and (max-width: 767px) {
    & .menu {
      max-height: 22rem !important;
    }
  }
`;

export const SelectLock = styled(Select)`
  width: calc(100% - 45px);
`;

export const DroplistLock = styled(Droplist)`
  width: calc(100% - 45px);
`;

export const CheckBoxesGroup = styled.div`
  display: block;

  .inline & {
    flex: 2;
  }
`;

export const CheckboxesLine = styled.div`
  clear: left;
  float: left;
  margin: 0 0 5px;

  &:last-child {
    margin: 0;
  }
`;

export const Checkbox = styled.input`
  float: left;
  display: inline-block;
  margin: 0 0 0 0;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid ${(props) => props.theme.border.light};

  .inline & {
    float: left;
    clear: left;
  }
`;

export const RadioGroup = styled.div`
  display: block;

  .radios-group & {
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }

  .inline & {
    flex: 2;
  }
`;

export const RadioLine = styled.div`
  clear: left;
  float: left;
  margin: 15px 0 0;
  width: 100%;
`;

export const RadioSegment = styled.div`
  float: left;
  margin: 15px 15px 0 0;
`;

export const Radio = styled.input`
  float: left;
  display: inline-block;
  margin: 0 0 0 0;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid ${(props) => props.theme.border.light};

  .inline & {
    float: left;
    clear: left;
  }
`;

export const Separator = styled.div`
  margin: 10px 0;
  height: 1px;
  border-bottom: 1px solid ${(props) => props.theme.border.light};
`;

export const ValidIcon = styled(Icon)<{ status: boolean | null }>`
  position: absolute;
  right: 0;
  top: -3px;
  font-size: 20px !important;
  ${(props) =>
    props.status === null ? `color: ${props.theme.colors.neutral.main}` : ""}
  ${(props) =>
    props.status === true ? `color: ${props.theme.colors.valid.main}` : ""}
  ${(props) =>
    props.status === false ? `color: ${props.theme.colors.error.main}` : ""}
`;
