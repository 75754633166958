import React, { useEffect, useState } from "react";
import { ScannerFrame } from "./elements/frame.element";
import { CloseButton } from "./elements/buttons/close.element";
import useScanner from "./hooks/scanner.hook";
import ScannerPreview from "./elements/preview.element";

interface ScannerProps {
  onBarcodeScanned: (result: string) => void;
  onBtnCancelScanClick: () => void;
}

const Scanner: React.FC<ScannerProps> = ({
  onBarcodeScanned,
  onBtnCancelScanClick,
}) => {
  const { openMediaStream, onBtnScannerCloseClick, error } =
    useScanner(onBtnCancelScanClick);
  const [previewElement, setPreviewElement] = useState<HTMLVideoElement | null>(
    null
  );

  useEffect(() => {
    if (previewElement) {
      openMediaStream(previewElement, onBarcodeScanned);
    }
  }, [previewElement, openMediaStream, onBarcodeScanned]);

  return (
    <>
      {error && <p>{error.message}</p>}
      <ScannerFrame>
        <CloseButton onClick={onBtnScannerCloseClick}>✕</CloseButton>
        <ScannerPreview
          autoPlay
          playsInline
          muted
          ref={(videoElement) => {
            if (videoElement && !previewElement) {
              setPreviewElement(videoElement);
            }
          }}
        />
      </ScannerFrame>
    </>
  );
};

export default Scanner;
