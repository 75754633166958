import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import styled from "styled-components";

import { Form, CommandField } from "../../../core/layout/form/form.elements";
import {
  ButtonCancel,
  ButtonSubmit,
} from "../../../core/layout/button/button.elements";

import { Icon } from "semantic-ui-react";

import OriginForm from "./elements/origine.form";
import DestinationsForm from "./elements/destinations.form";

import { MatOxyAppareil } from "../../../../interfaces/matoxy.interface";

import config from "../../../../config";

const StyledForm = styled(Form)`
  margin: 15px 0 0;
  padding-top: 0;
  border-top: 0;
`;

const ResultOK = styled.div<{ iResultat: number }>`
  position: fixed;
  left: 0;
  bottom: ${(props) => (props.iResultat !== 1 ? "-60px" : "0px")};
  height: 60px;
  width: 100%;
  line-height: 60px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  background: ${(props) => props.theme.colors.valid.main};
  transition: bottom 0.5s ease-in-out;
`;

const ResultKO = styled.div<{ iResultat: number }>`
  position: fixed;
  left: 0;
  bottom: ${(props) => (props.iResultat !== -1 ? "-60px" : "0px")};
  height: 60px;
  width: 100%;
  line-height: 60px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  background: ${(props) => props.theme.colors.error.main};
  transition: bottom 0.5s ease-in-out;
`;

const RemplissageCuvesForm: FunctionComponent = () => {
  const [bLoading, setLoading] = useState<boolean>(false);
  const [formValide, setFormValide] = useState<boolean>(false);
  const [bFormOrigineValide, setFormOrigineValide] = useState<boolean | null>(
    null
  );
  const [bFormDestinationValide, setFormDestinationValide] = useState<
    boolean | null
  >(null);
  const [tank, setTank] = useState<MatOxyAppareil | null>(null);
  const [tAppareil, setAppareils] = useState<MatOxyAppareil[]>([]);
  const [sCodeBarreCuve, setCodeBarreCuve] = useState<string>("");
  const [iResultat, setResultat] = useState<number>(0);

  const valideForm = useCallback((): void => {
    if (tank) {
      setFormOrigineValide(true);
    } else {
      setFormOrigineValide(null);
    }

    if (tAppareil.length > 0) {
      setFormDestinationValide(true);
    } else {
      setFormDestinationValide(null);
    }

    if (tank !== null && tAppareil.length > 0) {
      setFormValide(true);
    } else {
      setFormValide(false);
    }
  }, [tank, tAppareil.length]);

  const sendRequest = useCallback(
    async (evt: React.FormEvent<HTMLFormElement>): Promise<void> => {
      evt.preventDefault();

      if (!formValide) {
        return;
      }

      setLoading(true);

      try {
        const response = await axios(`${config.apiURL}/mat/dm/oxy/importe`, {
          method: "POST",
          data: {
            tank,
            tAppareil,
          },
          withCredentials: true,
        }).catch((err: any) => {
          throw new Error(err.response.data.sMessage);
        });

        const resultat = response.data.data;

        restoreForm();

        setResultat(resultat ? 1 : -1);
        setLoading(false);
        setTimeout(() => {
          setResultat(0);
        }, 2000);
      } catch (err: any) {
        console.error(err.message ?? "Une erreur est survenue");
        setLoading(false);
        setResultat(-1);
        setTimeout(() => {
          setResultat(0);
        }, 2000);
      }
    },
    [formValide, tank, tAppareil]
  );

  const restoreForm = () => {
    setCodeBarreCuve("");
    setAppareils([]);
  };

  useEffect(() => {
    valideForm();
  });

  return (
    <>
      <OriginForm
        bValide={bFormOrigineValide}
        tank={tank}
        setTank={setTank}
        tAppareil={tAppareil}
      />
      <DestinationsForm
        disabled={tank === null}
        tAppareil={tAppareil}
        setAppareils={setAppareils}
        sCodeBarreCuve={sCodeBarreCuve}
        setCodeBarreCuve={setCodeBarreCuve}
        bValide={bFormDestinationValide}
      />
      <StyledForm onSubmit={sendRequest}>
        <CommandField>
          <ButtonCancel type="button" onClick={restoreForm}>
            Effacer
          </ButtonCancel>
          <ButtonSubmit disabled={!formValide || bLoading} type="submit">
            {bLoading ? (
              <Icon name="hourglass outline" className="loading" />
            ) : (
              "Enregistrer"
            )}
          </ButtonSubmit>
        </CommandField>
      </StyledForm>

      <ResultOK iResultat={iResultat}>
        Les mouvements ont bien été enregistrés !
      </ResultOK>
      <ResultKO iResultat={iResultat}>
        Erreur ! Les mouvements n'ont pas été enregistrés...
      </ResultKO>
    </>
  );
};

export default RemplissageCuvesForm;
