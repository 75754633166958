import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

interface NavigationProps {
  show: boolean;
  hideNavigation(): void;
}

interface NavigationWrapperProps {
  show: boolean;
}

const NavigationWrapper = styled.div<NavigationWrapperProps>`
  position: fixed;
  top: 32px;
  z-index: 10;
  right: ${(props) => (props.show ? "0px" : "-240px")};
  opacity: ${(props) => (props.show ? 1 : 0)};
  width: 240px;
  height: calc(100vh - 32px);
  background: #fff;
  transition: all 0.3s ease-in-out;
  box-shadow: ${(props) =>
    props.show ? "0 0 15px 0 rgba(0, 0, 0, 0.25)" : "none"};
`;

const NavigationMenu = styled.ul``;

const NavigationMenuItem = styled.li`
  list-style: none;
`;

const NavigationMenuItemLink = styled(NavLink)`
  display: block;
  padding: 20px 10px;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
`;

export const Navigation: FunctionComponent<NavigationProps> = ({
  show,
  hideNavigation,
}) => {
  return (
    <NavigationWrapper show={show}>
      <NavigationMenu>
        <NavigationMenuItem>
          <NavigationMenuItemLink to="/accueil" onClick={hideNavigation}>
            Accueil
          </NavigationMenuItemLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuItemLink to="/bouteilleO2" onClick={hideNavigation}>
            Réception de bouteilles O2
          </NavigationMenuItemLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuItemLink to="/cuves" onClick={hideNavigation}>
            Fractionnement de cuves
          </NavigationMenuItemLink>
        </NavigationMenuItem>
        <NavigationMenuItem>
          <NavigationMenuItemLink to="/deconnexion" onClick={hideNavigation}>
            Déconnexion
          </NavigationMenuItemLink>
        </NavigationMenuItem>
      </NavigationMenu>
    </NavigationWrapper>
  );
};
