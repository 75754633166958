import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { Form, CommandField } from "../../../core/layout/form/form.elements";
import {
  ButtonCancel,
  ButtonSubmit,
} from "../../../core/layout/button/button.elements";

import config from "../../../../config";

import {
  Localisation,
  Emplacement,
} from "../../../../interfaces/localisation.interface";
import { BouteilleO2 } from "../../../../interfaces/bouteilleO2.interface";
import { LotBO2 } from "../../../../interfaces/lotBO2.interface";

import LocalisationBO2Form, {
  LocalisationFormHandles,
} from "./elements/localisationBO2.form";
import BouteilleO2Form, {
  BouteilleFormHandles,
} from "./elements/bouteilleO2.form";
import LotBO2Form, { LotFormHandles } from "./elements/lotBO2.form";

const StyledForm = styled(Form)`
  margin: 10px 0 0;
  padding-top: 0;
  border-top: 0;
`;

const ResultOK = styled.div<{ iResultat: number }>`
  position: fixed;
  left: 0;
  bottom: ${(props) => (props.iResultat !== 1 ? "-60px" : "0px")};
  height: 60px;
  width: 100%;
  line-height: 60px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  background: ${(props) => props.theme.colors.valid.main};
  transition: bottom 0.5s ease-in-out;
`;

const ResultKO = styled.div<{ iResultat: number }>`
  position: fixed;
  left: 0;
  bottom: ${(props) => (props.iResultat !== -1 ? "-60px" : "0px")};
  height: 60px;
  width: 100%;
  line-height: 60px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  background: ${(props) => props.theme.colors.error.main};
  transition: bottom 0.5s ease-in-out;
`;

const ReceptionBouteilleO2Form: FunctionComponent = () => {
  const [bLoading, setLoading] = useState<boolean>(false);
  const [formValide, setFormValide] = useState<boolean>(false);
  const [bFormBO2Valide, setFormBO2Valide] = useState<boolean | null>(null);
  const [bFormLotValide, setFormLotValide] = useState<boolean | null>(null);
  const [bFormLocalisationValide, setFormLocalisationValide] = useState<
    boolean | null
  >(null);
  const [iResultat, setResultat] = useState<number>(0);

  // Bouteille O2
  const bouteilleFormRef = useRef<BouteilleFormHandles>(null);
  const [bouteilleO2, setBouteille] = useState<BouteilleO2 | null>(null);
  const [sCodeBarreBouteille, setCodeBarreBouteille] = useState<string>("");
  const [modele, setModele] = useState<number>(0);
  const [modeleDefaut, setModeleDefaut] = useState<number>(0);
  const [bDefault, setDefault] = useState<boolean>(false);

  // Lot
  const lotFormRef = useRef<LotFormHandles>(null);
  const [lotBO2, setLot] = useState<LotBO2 | null>(null);
  const [sCodeBarreLot, setCodeBarreLot] = useState<string>("");

  // Localisation
  const localisationFormRef = useRef<LocalisationFormHandles>(null);
  const [localisationBO2Defaut, setLocalisationBO2Defaut] =
    useState<Localisation | null>(null);
  const [localisationBO2, setLocalisation] = useState<Localisation | null>(
    null
  );
  const [emplacement, setEmplacement] = useState<Emplacement | null>(null);

  const setLocalisationBO2 = (localisation: Localisation | null) => {
    setLocalisation(localisation);

    if (localisation?.bEmplacement) {
      bouteilleFormRef?.current?.enableEmplacements(
        localisation,
        bouteilleO2?.iFKProduit || null
      );
    } else {
      bouteilleFormRef?.current?.disableEmplacements();
    }
  };

  const setBouteilleO2 = (bouteille: BouteilleO2 | null) => {
    setBouteille(bouteille);

    if (bouteille !== null) {
      if (bouteille.sRefModele) {
        bouteilleFormRef?.current?.disableModeles();
      }
      if (localisationBO2?.bEmplacement) {
        localisationFormRef?.current?.reload();
      }
      localisationFormRef?.current?.reset();
      lotFormRef?.current?.enable(lotBO2 === null);
    } else if (!bDefault) {
      bouteilleFormRef?.current?.enableModeles();
      bouteilleFormRef?.current?.focus();
    } else {
      bouteilleFormRef?.current?.focus();
    }
  };

  const setModeleBO2 = (modele: number): void => {
    setModele(modele);
    if (bouteilleO2?.iFKProduit === 0) {
      setBouteille({ ...bouteilleO2, iFKProduit: modele });
    }
  };

  const setLotBO2 = (lot: LotBO2 | null) => {
    setLot(lot);

    if (lot !== null) {
      if (sCodeBarreLot !== "") {
        lotFormRef?.current?.disable();
      }
    } else {
      lotFormRef?.current?.enable(false);
    }
  };

  const receptionne = async (
    evt: React.FormEvent<HTMLButtonElement>
  ): Promise<void> => {
    evt.preventDefault();
    setLoading(true);
    try {
      const response = await axios(`${config.apiURL}/mat/dm/bo2/reception`, {
        method: "POST",
        data: {
          bouteille: {
            ...bouteilleO2,
            iFKProduit: modele,
          },
          lot: lotBO2,
          localisation: {
            ...localisationBO2,
            iFKEmplacement: emplacement?.iPKEmplacement,
          },
        },
        withCredentials: true,
      }).catch((err: any) => {
        setLoading(false);
        throw new Error(err.response.data.sMessage);
      });

      const resultat = response.data.data;

      if (resultat) {
        restoreForm();
      }
      // setBouteille(null);
      // setBouteilleO2(null);
      // setCodeBarreBouteille("");
      // if (!bDefault) {
      //   setModele(0);
      // }

      // setLotBO2(null);
      // setCodeBarreLot("");
      // setFormValide(false);
      // lotFormRef?.current?.reset();
      // bouteilleFormRef?.current?.clearResBouteille();
      // bouteilleFormRef?.current?.focus();
      setResultat(resultat ? 1 : -1);
      setLoading(false);
      setTimeout(() => {
        setResultat(0);
      }, 2000);
    } catch (err: any) {
      setLoading(false);
      setResultat(-1);
      console.error(err.message ?? "Une erreur est survenue");
      setTimeout(() => {
        setResultat(0);
      }, 2000);
    }
  };

  const restoreForm = (): void => {
    bouteilleFormRef?.current?.clearResBouteille();
    bouteilleFormRef?.current?.focus();
    setBouteille(null);

    setCodeBarreBouteille("");
    if (!bDefault) {
      setModele(0);
    }

    // setLocalisation(null);
    // localisationFormRef?.current?.reset();
    setBouteilleO2(null);
    setLotBO2(null);
    setCodeBarreLot("");
    setFormValide(false);

    if (localisationBO2?.bEmplacement) {
      bouteilleFormRef?.current?.enableEmplacements(localisationBO2, null);
    } else {
      bouteilleFormRef?.current?.disableEmplacements();
    }
    lotFormRef?.current?.reset();
  };

  const valideForm = useCallback((): void => {
    if (localisationBO2) {
      setFormLocalisationValide(true);
    } else {
      setFormLocalisationValide(null);
    }

    if (bouteilleO2) {
      setFormBO2Valide(true);
    } else {
      setFormBO2Valide(null);
    }

    if (lotBO2) {
      setFormLotValide(true);
    } else {
      setFormLotValide(null);
    }

    if (bouteilleO2 && modele && localisationBO2 && lotBO2) {
      if (lotBO2.dLimiteConso && lotBO2.dLimiteConso !== "Invalid date") {
        if (localisationBO2.bEmplacement && emplacement === null) {
          setFormValide(false);
          setFormBO2Valide(false);
        } else {
          setFormValide(true);
        }
      } else {
        setFormValide(false);
        setFormLotValide(false);
      }
    } else {
      setFormValide(false);
    }
  }, [bouteilleO2, modele, localisationBO2, lotBO2, emplacement]);

  useEffect(() => {
    valideForm();
  });

  return (
    <>
      <LocalisationBO2Form
        ref={localisationFormRef}
        localisation={localisationBO2}
        localisationDefaut={localisationBO2Defaut}
        setLocalisation={setLocalisationBO2}
        setLocalisationDefaut={setLocalisationBO2Defaut}
        bValide={bFormLocalisationValide}
      />

      <BouteilleO2Form
        ref={bouteilleFormRef}
        bouteilleO2={bouteilleO2}
        setBouteille={setBouteilleO2}
        sCodeBarre={sCodeBarreBouteille}
        setCodeBarre={setCodeBarreBouteille}
        modele={modele}
        setModele={setModeleBO2}
        setModeleDefaut={setModeleDefaut}
        modeleDefaut={modeleDefaut}
        bDefault={bDefault}
        setDefault={setDefault}
        localisation={localisationBO2}
        emplacement={emplacement}
        setEmplacement={setEmplacement}
        bValide={bFormBO2Valide}
      />

      <LotBO2Form
        ref={lotFormRef}
        lotBO2={lotBO2}
        sCodeBarre={sCodeBarreLot}
        setCodeBarre={setCodeBarreLot}
        setLotBO2={setLotBO2}
        bValide={bFormLotValide}
      />

      <StyledForm
        onSubmit={(evt: React.FormEvent<HTMLFormElement>) =>
          evt.preventDefault()
        }
      >
        <CommandField>
          <ButtonCancel type="button" onClick={restoreForm}>
            Effacer
          </ButtonCancel>
          <ButtonSubmit
            type="submit"
            onClick={receptionne}
            disabled={!formValide || bLoading}
          >
            {bLoading ? (
              <Icon name="hourglass outline" className="loading" />
            ) : (
              "Réceptionner"
            )}
          </ButtonSubmit>
        </CommandField>
      </StyledForm>

      <ResultOK iResultat={iResultat}>
        La bouteille a bien été réceptionnée
      </ResultOK>
      <ResultKO iResultat={iResultat}>
        Erreur ! La bouteille n'a pas été réceptionnée...
      </ResultKO>
    </>
  );
};

export default ReceptionBouteilleO2Form;
