import styled from "styled-components";

export const ScannerFrame = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 110;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background: ${(props) => props.theme.colors.neutral.main};
`;
