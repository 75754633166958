import React from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./ui/assets/css/global-css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ProtectedRoute from "./components/core/routes/ProtectedRoute";

import AnimatedSwitch from "./components/core/routes/AnimatedSwitch";
import Header from "./components/core/layout/view/header.elements";
import IndexView from "./components/views/index/index.view";
import ConnexionView from "./components/views/utilisateur/connexion/connexion.view";
import DeconnexionView from "./components/views/utilisateur/deconnexion/deconnexion.view";
import AccueilView from "./components/views/accueil/accueil.view";
import BouteilleO2View from "./components/views/bouteilleO2/bouteilleO2.view";
import CuvesView from "./components/views/cuves/cuves.view";

import "./ui/assets/css/datepicker.css";
import "semantic-ui-css/semantic.min.css";

const { theme } = require(`./ui/ads.theme.ts`);

function App() {
  if (screen.orientation?.lock) {
    screen.orientation.lock("portrait");
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Header />
        <AnimatedSwitch>
          <ProtectedRoute path="/" exact component={IndexView} />
          <Route path="/connexion" component={ConnexionView} />
          <ProtectedRoute path="/deconnexion" component={DeconnexionView} />
          <ProtectedRoute path="/accueil" component={AccueilView} />
          <ProtectedRoute path="/bouteilleO2" component={BouteilleO2View} />
          <ProtectedRoute path="/cuves" component={CuvesView} />
        </AnimatedSwitch>
      </Router>
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
