import styled from "styled-components";
import { ButtonLink } from "../../core/layout/button/button.elements";

export const Intro = styled.div`
  margin: 20px 0;
`;

export const BouttonSection = styled(ButtonLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px auto 30px;
  padding: 0;
  width: ${window.innerWidth / 2}px;
  height: ${window.innerWidth / 2}px;
  font-size: 24px;
  border-radius: 10px;
  border: 4px solid ${(props) => props.theme.colors.primary.main};
  background: #fff;

  @media (max-width: 320px) {
    font-size: 20px;
  }
`;
