import React, { useState, useLayoutEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

import { Button } from "../button/button.elements";
import { Navigation } from "../navigation/navigation.elements";

const AppHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: white;
  border-bottom: 1px solid #dddddd;
`;

const PageNavigationContainer = styled.div`
  position: absolute;
  left: 0;
`;

const PageTitleContainer = styled.div``;

const PageTitle = styled.div`
  font-size: 18px;
  cursor: default;
`;

const BouttonRetour = styled(Button)`
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  color: ${(props) => props.theme.text.primary};
  background: transparent;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.primary.main};
    background: transparent;
  }
`;

const BouttonNavigation = styled(Button)`
  height: 32px;
  line-height: 32px;
  font-size: 22px;
  font-weight: bold;
  color: ${(props) => props.theme.text.primary};
  background: transparent;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.primary.main};
    background: transparent;
  }
`;

const NavigationContainer = styled.div`
  position: absolute;
  right: 0;
`;

function Header() {
  const location = useLocation();
  const history = useHistory();
  const [titleKey, setTitleKey] = useState<string>("");
  const [bNavigation, setNavigation] = useState<boolean>(false);

  useLayoutEffect(() => {
    setTitleKey(location.pathname);
  }, [location.pathname]);

  const variants = {
    initial: { opacity: 0 },
    enter: { opacity: 1 },
    exit: { opacity: 0, display: "none" },
  };

  const hideNavigation = (): void => {
    setNavigation(false);
  };

  return (
    <>
      <AppHeader>
        <PageNavigationContainer>
          <AnimatePresence>
            {["/", "/accueil", "/deconnexion", "/connexion"].indexOf(
              location.pathname
            ) === -1 && (
              <motion.div
                initial="initial"
                animate="enter"
                exit="exit"
                variants={variants}
              >
                <BouttonRetour
                  onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                    evt.preventDefault();

                    // history.goBack();
                    history.push("/accueil");
                  }}
                >
                  <Icon name="arrow left" />
                </BouttonRetour>
              </motion.div>
            )}
          </AnimatePresence>
        </PageNavigationContainer>
        <PageTitleContainer>
          <AnimatePresence>
            {location.pathname !== "/" && (
              <motion.div
                // when titleKey changes our title will re-render and trigger fade in/out
                key={titleKey || ""}
                initial="initial"
                animate="enter"
                exit="exit"
                variants={variants}
                style={{
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
              >
                <PageTitle>{document.title.split(" |")[0]}</PageTitle>
              </motion.div>
            )}
          </AnimatePresence>
        </PageTitleContainer>
        <NavigationContainer>
          {["/", "/connexion", "/deconnexion"].indexOf(location.pathname) ===
            -1 && (
            <BouttonNavigation
              onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                evt.preventDefault();
                setNavigation(!bNavigation);
              }}
            >
              {bNavigation ? <span>✕</span> : <Icon name="bars" />}
            </BouttonNavigation>
          )}
        </NavigationContainer>
      </AppHeader>
      <Navigation show={bNavigation} hideNavigation={hideNavigation} />
    </>
  );
}

export default Header;
