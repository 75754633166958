import React, {
  forwardRef,
  useEffect,
  useState,
  useCallback,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { selectUtilisateur } from "../../../../../redux/utilisateur/utilisateur.slice";

import config from "../../../../../config";

import {
  Form,
  Field,
  FieldContent,
  Legend,
  Droplist,
  ValidIcon,
} from "../../../../core/layout/form/form.elements";

import { Localisation } from "../../../../../interfaces/localisation.interface";

interface LocalisationFormProps {
  localisation: Localisation | null;
  localisationDefaut: Localisation | null;
  setLocalisation: (localisation: Localisation | null) => void;
  setLocalisationDefaut: (localisation: Localisation | null) => void;
  bValide: boolean | null;
}

export interface LocalisationFormHandles {
  reset(): void;
  reload(): void;
}

const LocalisationForm: ForwardRefRenderFunction<
  LocalisationFormHandles,
  LocalisationFormProps
> = (
  {
    localisation,
    localisationDefaut,
    setLocalisation,
    setLocalisationDefaut,
    bValide,
  },
  ref
) => {
  const { iPKAnnuaire } = useSelector(selectUtilisateur);
  const [tLocalisation, setLocalisations] = useState<Localisation[]>([]);

  useImperativeHandle(ref, () => ({
    reset: () => {},

    reload: () => {},
  }));

  const chargeLocalisations = useCallback(async () => {
    if (tLocalisation.length === 0) {
      try {
        const response = await axios(`${config.apiURL}/mat/loc/cherche`, {
          method: "POST",
          data: {
            iPKIntervenant: iPKAnnuaire,
          },
          withCredentials: true,
        }).catch((err: any) => {
          throw new Error(err.response.data.sMessage);
        });

        const tLocalisationBO2 = response.data.data.filter(
          (l: Localisation) =>
            l.bLocalisationBO2 && l.sNatureLocalisation === "A"
        );
        const localisationBO2Defaut =
          tLocalisationBO2.find((l: Localisation) => l.bLocalisationRetourDM) ||
          null;

        setLocalisations(tLocalisationBO2);
        setLocalisationDefaut(localisationBO2Defaut);
        setLocalisation(localisationBO2Defaut);
      } catch (err: any) {
        console.error(err.message ?? "Une erreur est survenue");
        setLocalisations([]);
        setLocalisation(null);
        setLocalisationDefaut(null);
      }
    }
  }, [
    iPKAnnuaire,
    setLocalisation,
    setLocalisationDefaut,
    tLocalisation.length,
  ]);

  useEffect(() => {
    chargeLocalisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLocalisationChange = (
    evt: React.FormEvent<HTMLSelectElement>,
    data: any
  ): void => {
    if (parseInt(data.value, 10) !== -1) {
      const selectedLocalisation =
        tLocalisation.find(
          (l: Localisation) =>
            l.iPKLocalisation === parseInt(data.value || 0, 10)
        ) || null;

      setLocalisation(selectedLocalisation);
    }
  };

  const tLocalisationOption: any[] = [
    { key: -1, text: "Sélectionnez une localisation", value: -1 },
  ];
  tLocalisation.forEach((l) => {
    tLocalisationOption.push({
      key: l.iPKLocalisation,
      text:
        localisationDefaut?.iPKLocalisation === l.iPKLocalisation ? (
          <div>
            <span
              style={{ float: "right", marginRight: "20px" }}
            >{`${" "}Loc. par défaut`}</span>
            {l.sLibelleLocalisation}
          </div>
        ) : (
          l.sLibelleLocalisation
        ),
      value: l.iPKLocalisation,
      className:
        localisationDefaut?.iPKLocalisation === l.iPKLocalisation
          ? "default"
          : "",
    });
  });

  return (
    <Form
      onSubmit={(evt: React.FormEvent<HTMLFormElement>) => evt.preventDefault()}
    >
      <Legend>
        <ValidIcon status={bValide} name="check circle outline" />
        Localisation de réception
      </Legend>

      <Field className="inline">
        <FieldContent>
          <Droplist
            fluid
            clearable
            options={tLocalisationOption}
            selection
            placeholder="Sélectionnez une localisation"
            onChange={handleLocalisationChange}
            id="localisationBO2"
            value={localisation?.iPKLocalisation || 0}
          />
        </FieldContent>
      </Field>
    </Form>
  );
};

export default forwardRef(LocalisationForm);
