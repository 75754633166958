import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import { Modal } from "semantic-ui-react";

import {
  Form,
  Field,
  FieldContent,
  Legend,
  ValidIcon,
  Droplist,
  CommandField,
} from "../../../../core/layout/form/form.elements";
import { ButtonSubmit } from "../../../../core/layout/button/button.elements";

import { MatOxyAppareil } from "../../../../../interfaces/matoxy.interface";

import config from "../../../../../config";

interface OrigineFormProps {
  tank: MatOxyAppareil | null;
  setTank: (tank: MatOxyAppareil | null) => void;
  tAppareil: MatOxyAppareil[];
  bValide: boolean | null;
}

const OrigineForm: FunctionComponent<OrigineFormProps> = ({
  tank,
  setTank,
  tAppareil,
  bValide,
}) => {
  const [tTank, setTanks] = useState<MatOxyAppareil[]>([]);
  const [bAutorise, setAutorise] = useState<boolean>(true);

  const chargeTanks = useCallback(async () => {
    try {
      const response = await axios(`${config.apiURL}/mat/dm/oxy/appareils`, {
        method: "POST",
        data: {
          sType: "TANK",
        },
        withCredentials: true,
      }).catch((err: any) => {
        throw new Error(err.response.data.sMessage);
      });

      setTanks(response.data.data);

      if (response.data.data.length === 1) {
        setTank(response.data.data[0]);
      }
    } catch (err: any) {
      console.error(err.message ?? "Une erreur est survenue");
    }
  }, [setTank]);

  useEffect(() => {
    chargeTanks();
  }, [chargeTanks]);

  const handleTankChange = (
    evt: React.FormEvent<HTMLSelectElement>,
    data: any
  ): void => {
    if (parseInt(data.value, 10) !== -1) {
      if (tAppareil.length > 0) {
        setAutorise(false);
      } else {
        const selectedTank =
          tTank.find((t) => t.iPKProduitDM === parseInt(data.value || 0, 10)) ||
          null;

        setTank(selectedTank);
      }
    }
  };

  const tTankOption: any[] = [
    { key: -1, text: "Sélectionnez un tank", value: -1 },
  ];
  tTank.forEach((t) => {
    tTankOption.push({
      key: t.iPKProduitDM,
      text: `${t.sRefProduit} (${t.sLibelleModele})`,
      value: t.iPKProduitDM,
    });
  });

  return (
    <>
      <Form
        onSubmit={(evt: React.FormEvent<HTMLFormElement>) =>
          evt.preventDefault()
        }
      >
        <Legend>
          <ValidIcon status={bValide} name="check circle outline" />
          Tank d'origine
        </Legend>

        <Field>
          <FieldContent>
            <Droplist
              fluid
              clearable
              options={tTankOption}
              selection
              placeholder="Sélectionnez un tank"
              onChange={handleTankChange}
              value={tank?.iPKProduitDM || 0}
            />
          </FieldContent>
        </Field>
      </Form>

      <Modal
        dimmer="inverted"
        open={!bAutorise}
        onClose={() => setAutorise(true)}
      >
        <Modal.Content>
          <Form>
            <Legend>Mouvements en cours</Legend>
            <p>
              Vous devez enregistrer vos mouvements en cours avant de pouvoir
              sélectionner un nouveau tank.
            </p>
            <CommandField>
              <ButtonSubmit onClick={() => setAutorise(true)}>OK</ButtonSubmit>
            </CommandField>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default OrigineForm;
