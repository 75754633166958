import { AppConfig } from "./interfaces/config.interface";

const config: AppConfig = {
  version: process.env.REACT_APP_VERSION || "",
  name: process.env.REACT_APP_NAME || "",

  theme: process.env.REACT_APP_THEME || "ads",

  apiURL: process.env.REACT_APP_API_URL || "",

  calendar: {
    months: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    days: {
      long: [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ],
      short: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
    },
  },
};

export default config;
