import styled from "styled-components";
import { Form, CommandField } from "../../../core/layout/form/form.elements";

export const ConnexionFormWrapper = styled.div``;

export const Logo = styled.img`
  display: block;
  margin: 20px auto 0;
  width: 50%;
  height: auto;
  animation: App-logo-float infinite 3s ease-in-out;

  @media (max-width: 320px) {
    width: 25%;
  }
`;

export const AppName = styled.h1`
  margin: 0 0 75px;
  padding: 0;
  height: 30px;
  font-family: "UniversCondensedBold";
  font-size: 60px;
  text-align: center;
`;

export const AppNameEasy = styled.span`
  font-family: "UniversCondensed", sans-serif !important;
  font-weight: normal;
  font-size: 54px;
`;

export const AppNameStock = styled.span`
  font-weight: bold;
`;

export const FormConnexion = styled(Form)`
  padding-top: 60px;
  border: 0;
`;

export const CommandFieldConnexion = styled(CommandField)`
  border: 0;
`;

export const Version = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 20px;
  text-align: center;
  font-size: 12px;
  opacity: 0.5;
  cursor: default;
`;
