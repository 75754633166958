import React, { FunctionComponent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { connexionAction } from "../../../../redux/utilisateur/actions/connexion.action";
import { selectStoreErreur } from "../../../../redux/utilisateur/utilisateur.slice";
import { FormConnexion, CommandFieldConnexion } from "./connexion.elements";

import {
  Field,
  FieldLabel,
  FieldContent,
  Label,
  Input,
} from "../../../core/layout/form/form.elements";
import { ButtonSubmit } from "../../../core/layout/button/button.elements";
import { ErreurNotice } from "../../../core/layout/notice/notice.elements";

const FieldContentConnexion = styled(FieldContent)`
  .inline & {
    flex: 2;
  }
`;

const ConnexionForm: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [sIdentifiant, setIdentifiant] = useState<string>("");
  const [sMotDePasse, setMotDePasse] = useState<string>("");
  const [sErreur, setErreur] = useState<string>("");
  const sStoreErreur = useSelector(selectStoreErreur);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>): void => {
    evt.preventDefault();

    if (isValidForm()) {
      dispatch(connexionAction(sIdentifiant, sMotDePasse));
    }
  };

  const isValidForm = (): boolean => {
    if (sIdentifiant.length === 0) {
      setErreur("Veuillez saisir votre identifiant");
      return false;
    }

    if (sMotDePasse.length === 0) {
      setErreur("Veuillez saisir votre mot de passe");
      return false;
    }

    return true;
  };

  return (
    <FormConnexion onSubmit={handleSubmit}>
      <Field className={window.innerWidth > 320 ? "inline" : ""}>
        <FieldLabel>
          <Label>Identifiant</Label>
        </FieldLabel>
        <FieldContentConnexion>
          <Input
            type="text"
            id="sIdentifiant"
            value={sIdentifiant}
            onChange={(evt) => setIdentifiant(evt.target.value)}
          />
        </FieldContentConnexion>
      </Field>

      <Field className={window.innerWidth > 320 ? "inline" : ""}>
        <FieldLabel>
          <Label>Mot de passe</Label>
        </FieldLabel>
        <FieldContentConnexion>
          <Input
            type="password"
            id="sMotDePasse"
            value={sMotDePasse}
            onChange={(evt) => setMotDePasse(evt.target.value)}
          />
        </FieldContentConnexion>
      </Field>

      <CommandFieldConnexion>
        <ButtonSubmit type="submit">Connexion</ButtonSubmit>
      </CommandFieldConnexion>

      {sErreur && <ErreurNotice>{sErreur}</ErreurNotice>}
      {sStoreErreur && <ErreurNotice>{sStoreErreur}</ErreurNotice>}
    </FormConnexion>
  );
};

export default ConnexionForm;
