import { useState, useCallback, useEffect, useRef } from "react";
import { scanImageData } from "zbar.wasm";

const CONSTRAINTS = {
  video: {
    width: {
      min: 1280,
      ideal: 1920,
      max: 2560,
    },
    height: {
      min: 720,
      ideal: 1080,
      max: 1440,
    },
    facingMode: "environment",
  },
};

const SCAN_INTERVAL = 300;

interface UseMediaDevicesResult {
  onBtnScannerCloseClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  openMediaStream: (
    previewElement: HTMLVideoElement,
    onBarcodeScanned: (codebar: string) => void
  ) => void;
  error: Error | null;
}

const useScanner = (
  onBtnCancelScanClick: () => void | null
): UseMediaDevicesResult => {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [scanIntervalId, setScanIntervalId] = useState<NodeJS.Timeout | null>(
    null
  );
  const [prevResult, setPrevResult] = useState<string>("");
  const scanPreviewElement = useRef<HTMLVideoElement | null>(null);

  const takeScreenshot = useCallback(
    (previewElement: HTMLVideoElement) => {
      try {
        if (!previewElement) {
          throw new Error("Impossible de trouver l'élément vidéo");
        }

        const canvas = document.createElement("canvas");
        const canvasContext = canvas.getContext("2d");

        if (!canvasContext) {
          throw new Error(
            "Impossible de récupérer le contexte de l'appareil photo."
          );
        }

        canvas.height = previewElement.videoHeight;
        canvas.width = previewElement.videoWidth;

        canvasContext.drawImage(
          previewElement,
          0,
          0,
          canvas.width,
          canvas.height
        );

        return canvasContext.getImageData(0, 0, canvas.width, canvas.height);
      } catch (err) {
        if (mediaStream) {
          mediaStream.getTracks().forEach((track) => {
            track.stop();
          });
        }
        if (scanIntervalId) {
          clearInterval(scanIntervalId);
        }
        setMediaStream(null);
        setScanIntervalId(null);
        setError(err instanceof Error ? err : new Error("Unknown error"));
      }
    },
    [mediaStream, scanIntervalId]
  );

  const codeScanImage = useCallback(
    async (onBarcodeScanned: (codebar: string) => void) => {
      if (scanPreviewElement.current) {
        const data = takeScreenshot(scanPreviewElement.current);

        if (!data) {
          throw new Error("Impossible de récupérer l'image à scanner");
        }

        const result = (await scanImageData(data))[0]?.decode() || null;

        if (result) {
          // console.log("found: ", result);
          if (result !== prevResult) {
            setPrevResult(result);
            onBarcodeScanned(result);
          }
        }
      }
    },
    [takeScreenshot, prevResult]
  );

  const initializeMediaDevices = useCallback(
    async (
      previewElement: HTMLVideoElement,
      onBarcodeScanned: (codebar: string) => void
    ) => {
      try {
        scanPreviewElement.current = previewElement;
        const stream = await navigator.mediaDevices.getUserMedia(CONSTRAINTS);
        setMediaStream(stream);
        previewElement.srcObject = stream;
        if (scanIntervalId) {
          clearInterval(scanIntervalId);
        }
        const intervalId = setInterval(
          () => codeScanImage(onBarcodeScanned),
          SCAN_INTERVAL
        );
        setScanIntervalId(intervalId);
      } catch (err) {
        if (mediaStream) {
          mediaStream.getTracks().forEach((track) => {
            track.stop();
          });
        }
        if (scanIntervalId) {
          clearInterval(scanIntervalId);
        }
        setMediaStream(null);
        setScanIntervalId(null);
        setError(err instanceof Error ? err : new Error("Unknown error"));
      }
    },
    [codeScanImage, scanIntervalId, mediaStream]
  );

  const onBtnScannerCloseClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
        setMediaStream(null);
      }
      if (scanIntervalId) {
        clearInterval(scanIntervalId);
        setScanIntervalId(null);
      }
      if (onBtnCancelScanClick) {
        onBtnCancelScanClick();
      }
    },
    [mediaStream, scanIntervalId, onBtnCancelScanClick]
  );

  const openMediaStream = useCallback(
    (
      previewElement: HTMLVideoElement,
      onBarcodeScanned: (codebar: string) => void
    ) => {
      if (!mediaStream) {
        initializeMediaDevices(previewElement, onBarcodeScanned);
      }
    },
    [initializeMediaDevices, mediaStream]
  );

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (scanIntervalId) {
        clearInterval(scanIntervalId);
      }
    };
  }, [mediaStream, scanIntervalId]);

  return {
    onBtnScannerCloseClick,
    openMediaStream,
    error,
  };
};

export default useScanner;
